define(["./nlgAnalysisTreeModule", "angular", "../arrays/arrays"], function (nlgAnalysisTreeModule, angular, arrays) {
    "use strict";

    return nlgAnalysisTreeModule.service("nodePropertyFormatter", [
        "entityFormatFilter",
        "numberFilter",
        "currencyFilter",
        "dimensionFilter",
        "dateFilter",
        "durationFilter",
        "translateFilter",
        function (entityFormatFilter, numberFilter, currencyFilter, dimensionFilter, dateFilter, durationFilter, translateFilter) {
            return function nodePropertyFormatter(property) {

                var value = property.value;

                if(arrays.isArray(property.value)){
                    return arrays.map(property.value, function (value) {
                        return nodePropertyFormatter( {
                            type: property.type,
                            value: value
                        });
                    }).join(", ");
                }

                if (angular.isUndefined(value) || value === null) {
                    return "N/A";
                } else if (property.type === "ENTITY") {
                    return entityFormatFilter(value);
                } else if (property.type === "NUMBER") {
                    return numberFilter(value);
                } else if (property.type === "CURRENCY") {
                    return currencyFilter(value, "R$ ");
                } else if (property.type === "DIMENSION") {
                    return dimensionFilter(value);
                } else if (property.type === "DATETIME") {
                    return dateFilter(value, "short");
                } else if (property.type === "DATE") {
                    return dateFilter(value, "shortDate");
                } else if (property.type === "TIME") {
                    return dateFilter(value, "shortTime");
                } else if (property.type === "INTERVAL") {
                    return dateFilter(value.start, "short") + " - " + dateFilter(value.end, "short");
                } else if (property.type === "ACTION") {
                    return nodePropertyFormatter(property.innerNodeProperty);
                } else if (property.type === "DURATION") {
                    return durationFilter(value);
                } else if (property.type === "BOOLEAN") {
                    return translateFilter("nodeproperty.boolean." + value);
                } else if (property.type === "HTML") {
                    return "<span>" + translateFilter(value, property.messageArguments) + "<span>";
                } else if (typeof value === "string") {
                    return translateFilter(value);
                }
                return angular.toJson(value);
            };
        }]);
});