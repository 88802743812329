define(["./mapsModule"], function (mapsModule) {
    "use strict";

    return mapsModule.provider("carrierZoneGrouperService", [function () {
        var polygonCarrierZoneGrouperUrl;

        this.$get = ["$http", function ($http) {
            return {
                getPolygonCarrierZoneGroupers: function () {
                    return $http.get(polygonCarrierZoneGrouperUrl)
                        .then(function (response) {
                            return response.data;
                        });
                },

                getPolygons: function (grouper) {
                    return $http.post(polygonCarrierZoneGrouperUrl, grouper)
                        .then(function (response) {
                            return response.data;
                        });
                }
            };
        }];

        this.setPolygonCarrierZoneGrouperUrl = function (serviceUrl) {
            polygonCarrierZoneGrouperUrl = serviceUrl;
        };
    }]);
});