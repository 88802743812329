define([
        "./filterModule",
        "text!./nlgFilter.html"],
    function (filterModule, nlgFilterTemplate) {
        "use strict";

        /**
         * @ngdoc directive
         * @name nlgFilter
         * @restrict E
         *
         * @description
         *
         * @param {boolean=} hideSortButton Se presente esconde o botão "Ordenar"
         * @param {boolean=} hideSearchButton Se presente esconde o botão "Pesquisar"
         * @param {string} service
         * @param {Function} filterAction
         *
         * @example
         <example>
         <file name="nlg-filter.html">
         <div>
         <nlg-filter  service="service" filter-action="filterAction(patch)" hide-sort-button hide-search-button></nlg-filter>
         </div>
         </file>
         </example>
         */
        filterModule.directive("nlgFilter", [function () {
            return {
                restrict: "E",
                template: nlgFilterTemplate,
                scope: {
                    serviceName: "@service",
                    filterAction: "&",
                    onRegisterApi: "&?",
                    domain: "@?",
                    showOperator: "=?",
                    filterService: "=?",
                    preSelectedFilters: "<?",
                    extraActions: "=?",
                    hideSearchButton: "@?",
                    hideSortButton: "@?",
                    translateStringTags: "=?"
                },
                controller: "filterController"
            };
        }]);
    });