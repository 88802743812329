define(["./numberModule"], function (numberModule) {
    "use strict";

    return numberModule.filter("volume", ["$filter", function ($filter) {
        var numberFilter = $filter("number");
        return function (volume) {
            if (!volume || isNaN(volume)) {
                return "0 m³";
            }
            return numberFilter(Math.round(volume * 10) / 10) + " m³";
        };
    }]);
});