define([
    "./autoRefreshModule",
    "text!./nlgAutoRefresh.html"
], function (module, template) {
    "use strict";

    var SECONDS = 1000;
    var MINUTES = 60 * SECONDS;

    return module.directive("nlgAutoRefresh", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                onRefresh: "&",
                interval: "<?",
                intervals: "<?"
            },
            controller: ["$scope", "$window", "$timeout", function ($scope, $window, $timeout) {
                var autoRefreshInterval;
                $scope.interval = $scope.interval || 0;
                $scope.intervals = $scope.intervals || [0, 15 * SECONDS, 30 * SECONDS, MINUTES, 5 * MINUTES, 10 * MINUTES];

                createInterval();
                $scope.$on("$destroy", cancelInterval);

                $scope.toggleInterval = function (newInterval) {
                    $scope.interval = newInterval;
                    createInterval();
                    executeInterval();
                };

                function createInterval() {
                    cancelInterval();
                    if ($scope.interval) {
                        autoRefreshInterval = $timeout(function () {
                            $window.requestAnimationFrame(function () {
                                executeInterval();
                                createInterval();
                            });
                        }, $scope.interval);
                    }
                }

                function cancelInterval() {
                    $timeout.cancel(autoRefreshInterval);
                }

                function executeInterval() {
                    if ($scope.interval) {
                        $timeout($scope.onRefresh);
                    }
                }
            }]
        };
    }]);
});