define([
    "./addressModule",
    "text!./nlgAddressEdition.html",
    "text!./AddressSelection.html",
    "angular",
    "../objects/objects",
    "../arrays/arrays",
    "./addressDependencyHelper"
], function (addressModule, template, addressSelectionTemplate, angular, objects, arrays, addressDependencyHelper) {
    "use strict";

    return addressModule.directive("nlgAddressEdition", [function () {
        return {
            restrict: "E",
            template: template,
            scope: {
                entity: "=",
                entityDto: "=",
                ngDisabled: "&",
                hidePhone: "@?"
            },
            controller: ["$scope", "$http", "$modal", "autocompleteFactory", "messagesModal", "remoteExceptionHandler", "addressServiceUrls", "loadingService", function ($scope, $http, $modal, autocompleteFactory, messagesModal, remoteExceptionHandler, addressServiceUrls, loadingService) {

                function createAutocomplete(address) {
                    $scope.country = autocompleteFactory.lazyLoader(addressServiceUrls.countryUrl);
                    $scope.state = autocompleteFactory.lazyLoader(addressServiceUrls.stateUrl, adaptToDto(address, "country", "br.com.neolog.model.Country"));
                    $scope.city = autocompleteFactory.lazyLoader(addressServiceUrls.cityUrl, [
                        adaptToDto(address, "country", "br.com.neolog.model.Country"),
                        adaptToDto(address, "state", "br.com.neolog.model.State")]);
                }

                function adaptToDto(obj, propertyName, className){
                    return function(){
                        var value = obj[propertyName];
                        if(angular.isString(value)){
                            return {
                                name: value,
                                entityClass: className
                            };
                        }
                        return value;
                    };
                }

                createAutocomplete($scope.entity.address);

                var getStateIfCountryNotChange = addressDependencyHelper.getStateIfCountryNotChange();
                $scope.onCountryChanged = function () {
                    var address = $scope.entity.address;
                    address.state = getStateIfCountryNotChange(address.country, address.state);
                };

                var getCityIfStateNotChange = addressDependencyHelper.getCityIfStateNotChange();
                $scope.onStateChanged = function () {
                    var address = $scope.entity.address;
                    address.city = getCityIfStateNotChange(address.state, address.city);
                };

                $scope.zipDisabled = function () {
                    if ($scope.entity.address.country === null && $scope.entity.address.state === null) {
                        $scope.entityDto.zipCode = null;
                        return true;
                    }
                    return false;
                };

                $scope.hasAddress = function () {
                    if (hasCountry()) {
                        return false;
                    }
                    return $scope.entity.address.street !== null && $scope.entity.address.street !== "";
                };

                $scope.hasZipCode = function () {
                    if (hasCountry()) {
                        return false;
                    }
                    return $scope.entityDto.zipCode !== null && $scope.entityDto.zipCode !== "";
                };

                function hasCountry(){
                    return $scope.entity.address.country === null && $scope.entity.address.state === null;
                }


                $scope.cityDisabled = function () {
                    if ($scope.entity.address.state === null) {
                        $scope.entity.address.city = null;
                        return true;
                    }
                    return false;
                };

                /**
                 * procura por endereço, não considera latitude/longitude
                 */
                $scope.validateAddress = function () {
                    var address = angular.copy($scope.entity.address);
                    address.zipCode = $scope.entityDto.zipCode;
                    address.geographicInfo.latitude = null;
                    address.geographicInfo.longitude = null;
                    address.geocodificationType = "br.com.neolog.model.GeocodificationType.COMPLETE_ADDRESS";
                    doValidateAddress(address);
                };

                /**
                 * faz busca por endereço a partir da latitude/longitude
                 */
                $scope.validateGeographicInfo = function () {
                    var address = {};
                    address.geographicInfo = $scope.entity.address.geographicInfo;
                    address.geocodificationType = "br.com.neolog.model.GeocodificationType.MANUAL";
                    doValidateAddress(address);
                };

                /**
                 * procura por latitude e longitude do CEP e País, e na sequencia busca endereço completo a partir do lat/lng
                 */
                $scope.validateByZipCode = function () {
                    var address = {};
                    address.country = $scope.entity.address.country;
                    address.zipCode = $scope.entityDto.zipCode;
                    address.geocodificationType = "br.com.neolog.model.GeocodificationType.ZIP_CODE";
                    doValidateAddress(address);
                };

                var doValidateAddress = function (address) {
                    return loadingService(
                        $http.post(addressServiceUrls.geocodingUrl, address)
                            .success(function (validAddresses) {
                                if (validAddresses.length === 0) {
                                    openDialog("dialog.warning", ["edition.address.geocoding.notFound"]);
                                    return;
                                }

                                if (validAddresses.length === 1) {
                                    setAddress(validAddresses[0]);
                                    return;
                                }

                                $modal.open({
                                    template: addressSelectionTemplate,
                                    controller: "AddressSelectionController",
                                    resolve: {
                                        validAddresses: function () {
                                            return validAddresses;
                                        }
                                    }
                                }).result.then(function (selectedAddress) {
                                    setAddress(selectedAddress);
                                });
                            })
                            .catch(remoteExceptionHandler())
                    );

                    function setAddress(address) {
                        $scope.entityDto.zipCode = angular.isObject(address.zipCode) ? address.zipCode.value : address.zipCode;
                        $scope.entity.address.zipCode = $scope.entityDto.zipCode;
                        $scope.entity.address.geographicInfo = address.geographicInfo;

                        // Atualiza caso seja encontrado
                        $scope.entity.address.country = address.country !== null ? address.country : $scope.entity.address.country;
                        $scope.entity.address.state = address.state !== null ? address.state : $scope.entity.address.state;
                        $scope.entity.address.city = address.city !== null ? address.city : $scope.entity.address.city;
                        $scope.entity.address.district = address.district !== null ? address.district : $scope.entity.address.district;
                        $scope.entity.address.street = address.street !== null ? address.street : $scope.entity.address.street;
                        $scope.entity.address.geocodificationType = address.geocodificationType;

                        // A referência de entity.address deve ser atualizada no autocomplete
                        createAutocomplete($scope.entity.address);
                    }
                };

                $scope.hasGeocode = function () {
                    var address = $scope.entity.address;
                    var geographicInfo = address.geographicInfo;
                    return geographicInfo && geographicInfo.latitude && geographicInfo.longitude;
                };

                $scope.showPhone = function () {
                    return $scope.hidePhone !== "true";
                };

                function openDialog(header, messages) {
                    messagesModal(header, arrays.isArray(messages) ? messages : [messages]);
                }
            }]
        };
    }]);
});
