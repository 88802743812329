define([
    "lodash",
    "../../arrays/arrays",
    "./../nlgLGPDModule"
], function (_, arrays, lgpdModule) {
    "use strict";

    return lgpdModule.controller("nlgLGPDLogsController", [
        "$scope",
        "autocompleteFactory",
        "messagesModal",
        "remoteExceptionHandler",
        "$state",
        "$q",
        "loadingService",
        "nlgLGPDService",
        "$translate",
        "$filter",
        "xlsService",
        function ($scope, autocompleteFactory, messagesModal, remoteExceptionHandler, $state, $q, loadingService, nlgLGPDService, $translate, $filter, xlsService) {
            var paginationApi;
            $scope.maxResultsSpreadSheet = 0;

            $scope.model = {
                entityDefinition: null,
                entityField: null
            };

            $scope.getActionName = function (tag) {
                if (!tag) return "";
                return $translate.instant(tag);
            };

            $scope.getEntityName = function (tag) {
                if (!tag) return "";
                return "(" + _.capitalize($translate.instant(tag.artifactName)) + "/" +
                    _.capitalize(tag.translatedServiceName) + ") - " + tag.presentationName;
            };

            $scope.getEntityFieldName = function (tag) {
                if (!tag) return "";
                if (!tag.presentationField) return tag.entityField;
                return tag.presentationField;
            };

            $scope.getAllDefinitionEntityNames = function () {
                $scope.serviceName = nlgLGPDService.availableServiceNames();
                return $q.all(nlgLGPDService.getAlreadLogDefinitions())
                    .then(function (allItems) {
                        var resultNames = [];
                        allItems.forEach(function (entry) {
                            entry.value.forEach(function (name) {
                                var nameEntry = {
                                    entityName: name.entityName,
                                    presentationName: name.presentationName,
                                    artifactName: entry.key.artifactName,
                                    serviceName: name.type.description,
                                    translatedServiceName: $translate.instant(name.type.description)
                                };
                                resultNames.push(nameEntry);
                            });
                        });
                        return resultNames;
                    });
            };

            $scope.getEntitiesByName = function (definiton) {
                var template = {
                    entityName: definiton.entityName,
                    presentationName: definiton.presentationName,
                    type: {"description": definiton.serviceName},
                    artifactName: definiton.artifactName
                };
                return nlgLGPDService.getLogEntitiesByServicePathAndTemplate(template);
            };

            $scope.getLogTypes = function () {
                return nlgLGPDService.getLogTypes();
            };

            $scope.findEntities = function () {
                return paginationApi.seekPage(1);
            };

            $scope.checkEntity = function () {
                if (!$scope.model.entityDefinition) {
                    $scope.model.entityField = null;
                }
            };

            $scope.exportSpreedSheet = function () {
                var data = prepareRequestData();
                var dataToExport = [];
                nlgLGPDService.getlogEntities(data, 0, $scope.maxResultsSpreadSheet)
                    .then(function (response) {
                        arrays.each(response.data.logsEntities, function (logEntity) {
                            logEntity.entityName = $translate.instant(data.serviceName) + " - " + logEntity.entityName;
                            logEntity.action = $translate.instant(logEntity.action);
                            var parameters = JSON.parse(logEntity.parameters);
                            logEntity.justification = $translate.instant(logEntity.justification, parameters);
                            logEntity.success = $translate.instant(logEntity.success ? "lgpd.log.action.success" : "lgpd.log.action.fail");

                            var excelRow = {
                                auditedDate: $filter("date")(logEntity.auditedDate, "dd/MM/yyyy H:mm"),
                                userCode: logEntity.userCode,
                                entityName: logEntity.presentationName,
                                entityField: logEntity.presentationField || logEntity.entityField,
                                action: logEntity.action,
                                code: clearValue(logEntity.code),
                                name: clearValue(logEntity.name),
                                value: clearValue(logEntity.value),
                                status: logEntity.success,
                                justification: clearValue(logEntity.justification)
                            };
                            dataToExport.push(excelRow);
                        });
                        return xlsService("lgpd.logs.spreadsheet", dataToExport, spreadsheetStyle);
                    }).catch(remoteExceptionHandler());
            };

            $scope.exportDefinitions = function () {
                return loadingService($q.all(nlgLGPDService.getLgpdLogRows()))
                    .then(function (allItems) {
                        var dataToExport = [];
                        allItems.forEach(function (entry) {
                            var artifactName = _.capitalize(entry.key.artifactName);
                            entry.value.forEach(function (definition) {
                                delete definition.definitionId;
                                var type = $translate.instant(definition.type.description);
                                definition.auditedDate = $filter("date")(definition.auditedDate, "dd/MM/yyyy H:mm");
                                definition.presentationName = artifactName + " - (" + type + ") " + definition.presentationName;
                                delete definition.type;
                                dataToExport.push(definition);
                            });
                        });
                        return xlsService("lgpd.logs.spreadsheet.definitions", dataToExport, spreadsheetStyle);

                    });
            };

            $scope.tableOptions = {
                records: [],
                onRegisterPagination: function (api) {
                    paginationApi = api;
                },
                pagination: {
                    onPaginate: function (firstResult, maxResults) {

                        var data = prepareRequestData();

                        return loadingService(nlgLGPDService.getlogEntities(data, firstResult, maxResults)
                            .then(function (response) {
                                $scope.maxResultsSpreadSheet = response.data.count;
                                var definitions = [];
                                arrays.each(response.data.logsEntities, function (logEntity) {
                                    logEntity.presentationName = $translate.instant(data.serviceName) + " - " + logEntity.presentationName;
                                    logEntity.action = $translate.instant(logEntity.action);
                                    var parameters = JSON.parse(logEntity.parameters);
                                    logEntity.justification = $translate.instant(logEntity.justification, parameters);
                                    logEntity.success = $translate.instant(logEntity.success ? "lgpd.log.action.success" : "lgpd.log.action.fail");
                                    definitions.push(logEntity);
                                });
                                return {
                                    result: definitions,
                                    count: response.data.count,
                                    firstResult: response.data.firstResult,
                                    maxResults: maxResults
                                };
                            }).catch(remoteExceptionHandler()));
                    }
                }
            };

            function prepareRequestData() {
                var entityField = null;
                var logType = null;
                if ($scope.model.entityField && $scope.model.entityField.entityField) {
                    entityField = $scope.model.entityField.entityField;
                }
                if ($scope.model.action) {
                    logType = $scope.model.action;
                }
                var template = {
                    entityName: $scope.model.entityDefinition.entityName,
                    entityField: entityField,
                    type: {"description": $scope.model.entityDefinition.serviceName},
                    logType: logType
                };
                return {
                    findBy: template,
                    serviceName: $scope.model.entityDefinition.serviceName,
                    artifactName: $scope.model.entityDefinition.artifactName
                };
            }

            function clearValue(value) {
                return value === null ? "" : value;
            }

            var spreadsheetStyle = {
                headers: true,
                column: {
                    style: {
                        Font: {
                            Bold: "1",
                            Color: "#FFFFFF"
                        },
                        Alignment: {
                            Horizontal: "Center"
                        },
                        Interior: {
                            Color: "#1CBBB2",
                            Pattern: "Solid"
                        },

                    },
                    width: 170
                }
            };

        }]);
});
